export const NewsLaterReducerActions = {
  SET_EMAIL: "SET_EMAIL",
  RESET_EMAIL: "RESET_EMAIL",
  SET_IS_MODAL_OPEN: "SET_IS_MODAL_OPEN",
};

const defaultState = {
  email: "",
  isModalOpen: false,
};

export const newsLaterState = { ...defaultState };

export const newsLaterReducer = (state, action) => {
  switch (action.type) {
    case NewsLaterReducerActions.SET_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }
    case NewsLaterReducerActions.RESET_EMAIL: {
      return {
        ...state,
        email: "",
      };
    }
    case NewsLaterReducerActions.SET_IS_MODAL_OPEN: {
      return {
        ...state,
        isModalOpen: action.payload,
      };
    }
    default: {
      throw new Error(
        `No case for type ${action.type} found in newsLater Reducer.`
      );
    }
  }
};
