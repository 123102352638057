export const BannerWidthReducerActions = {
  SET_WIDTH: "SET_WIDTH",
  SET_HEIGHT: "SET_HEIGHT",
  RESET: "RESET",
  SET_NAV_HEIGHT: "SET_NAV_HEIGHT",
};

const defaultState = {
  width: 0,
  height: 0,
  navHeight: 0,
};

export const bannerWidthState = { ...defaultState };

export const bannerWidthReducer = (state, action) => {
  switch (action.type) {
    case BannerWidthReducerActions.SET_WIDTH: {
      return {
        ...state,
        width: action.payload,
      };
    }
    case BannerWidthReducerActions.SET_HEIGHT: {
      return {
        ...state,
        height: action.payload,
      };
    }
    case BannerWidthReducerActions.RESET: {
      return {
        ...defaultState,
      };
    }
    case BannerWidthReducerActions.SET_NAV_HEIGHT: {
      return {
        ...state,
        navHeight: action.payload,
      };
    }

    default: {
      throw new Error(
        `No case for type ${action.type} found in BannerWidth Reducer.`
      );
    }
  }
};
