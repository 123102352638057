import React from "react";
import "./src/styles/style.css";
import RootLayout from "./src/rootLayout";

require("prismjs/themes/prism-tomorrow.css");

export const wrapRootElement = ({ element }) => (
  <RootLayout>{element}</RootLayout>
);

const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript("//embed.redditmedia.com/widgets/platform.js");
  };
};
