import React, { useReducer, useState } from "react";
import BannerWidthContext from "./context/BannerWidthContext";
import NavContext from "./context/NavContext";
import NewsLaterContext from "./context/NewsLaterContext";
import {
  bannerWidthReducer,
  bannerWidthState,
} from "./reducers/BannerWidthReducer";
import {
  newsLaterReducer,
  NewsLaterReducerActions,
  newsLaterState,
} from "./reducers/newsLaterReducer";

const RootLayout = ({ children }) => {
  const [state, dispatch] = useReducer(bannerWidthReducer, bannerWidthState);
  const [_newsLaterState, _newsLaterDispatch] = useReducer(
    newsLaterReducer,
    newsLaterState
  );
  const [isShowWhiteLogo, setIsShowWhiteLogo] = useState(false);
  return (
    <BannerWidthContext.Provider value={{ state, dispatch }}>
      <NavContext.Provider value={{ isShowWhiteLogo, setIsShowWhiteLogo }}>
        <NewsLaterContext.Provider
          value={{
            state: _newsLaterState,
            dispatch: _newsLaterDispatch,
            actions: NewsLaterReducerActions,
          }}
        >
          {children}
        </NewsLaterContext.Provider>
      </NavContext.Provider>
    </BannerWidthContext.Provider>
  );
};

export default RootLayout;
